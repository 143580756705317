<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="contactLocationDataForm">
      <b-form
        @submit.prevent="validationForm"
      >
        <b-card
          class="p-2"
        >
          <b-row>
            <!-- email -->
            <b-col
              sm="12"
            >
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="3"
                label="Correo electrónico*"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="correo electrónico"
                  rules="email"
                  vid="email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    :state="errors.length > 0 ? false:null"
                    name="email"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- phone -->
            <b-col
              sm="12"
            >
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="2"
                label="Celular*"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="celular"
                  rules="max:9"
                  vid="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    :formatter="formatterNumber"
                    name="phone"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- homephone -->
            <b-col
              sm="12"
            >
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="2"
                label="Teléfono fijo"
                label-for="homephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="teléfono fijo"
                  rules="max:7"
                  vid="homephone"
                >
                  <b-form-input
                    id="homephone"
                    v-model="homephone"
                    :state="errors.length > 0 ? false:null"
                    :formatter="formatterNumber"
                    name="homephone"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- address -->
            <b-col
              sm="12"
              lg="6"
            >
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Dirección*"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="dirección"
                  rules="max:150"
                  vid="address"
                >
                  <b-form-input
                    id="address"
                    v-model="address"
                    :state="errors.length > 0 ? false:null"
                    name="address"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- street -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Urbanización"
                label-for="street"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Urbanización"
                  rules="max:20"
                  vid="street"
                >
                  <b-form-input
                    id="street"
                    v-model="street"
                    name="street"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- streetNumber -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Número"
                label-for="streetNumber"
              >
                <b-form-input
                  id="streetNumber"
                  v-model="streetNumber"
                  :formatter="formatterNumber"
                  name="streetNumber"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- block -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Manzana"
                label-for="block"
              >
                <b-form-input
                  id="block"
                  v-model="block"
                  name="block"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- lot -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Lote"
                label-for="lot"
              >
                <b-form-input
                  id="lot"
                  v-model="lot"
                  :formatter="formatterNumber"
                  name="lot"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- apartmentNumber -->
              <b-form-group
                label-cols-sm="6"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="N° Departamento"
                label-for="apartment"
              >
                <b-form-input
                  id="apartmentNumber"
                  v-model="apartmentNumber"
                  name="apartmentNumber"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- inside -->
              <b-form-group
                label-cols-sm="6"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Interior"
                label-for="inside"
              >
                <b-form-input
                  id="inside"
                  v-model="inside"
                  :formatter="formatterNumber"
                  name="inside"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- district -->
              <b-form-group
                label-cols-sm="6"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Distrito*"
                label-for="district"
              >
                <validation-provider
                  #default="{ errors }"
                  name="distrito"
                  vid="district"
                >
                  <b-form-input
                    id="district"
                    v-model="district"
                    :state="errors.length > 0 ? false:null"
                    name="district"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- province -->
              <b-form-group
                label-cols-sm="6"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Provincia*"
                label-for="province"
              >
                <validation-provider
                  #default="{ errors }"
                  name="provincia"
                  vid="province"
                >
                  <b-form-input
                    id="province"
                    v-model="province"
                    :state="errors.length > 0 ? false:null"
                    name="province"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- department -->
              <b-form-group
                label-cols-sm="6"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Departamento*"
                label-for="department"
              >
                <validation-provider
                  #default="{ errors }"
                  name="departamento"
                  vid="department"
                >
                  <b-form-input
                    id="department"
                    v-model="department"
                    :state="errors.length > 0 ? false:null"
                    name="department"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- country -->
              <b-form-group
                label-cols-sm="6"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="País*"
                label-for="country"
              >
                <validation-provider
                  #default="{ errors }"
                  name="país"
                  vid="country"
                >
                  <b-form-select
                    id="country"
                    v-model="country"
                    :options="optionsCountry"
                    text-field="varDescripcion"
                    value-field="varCodigo"
                    :state="errors.length > 0 ? false:null"
                    name="country"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- reference -->
              <b-form-group
                label-cols-sm="6"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Referencia*"
                label-for="reference"
              >
                <validation-provider
                  #default="{ errors }"
                  name="referencia"
                  vid="reference"
                >
                  <b-form-input
                    id="reference"
                    v-model="reference"
                    :state="errors.length > 0 ? false:null"
                    name="reference"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- ubigee -->
              <b-form-group
                label-cols-sm="6"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Ubigeo*"
                label-for="ubigee"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ubigeo"
                  rules="min:6|max:6"
                  vid="ubigee"
                >
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="'Ubigeo son las siglas oficiales para Código de Ubicación Geográfica que usan el INEI, el RENIEC y la ONPE para codificar las circunscripciones'"
                        variant="outline-primary"
                        class="btn-icon"
                        :disabled="!canEdit"
                      >
                        <feather-icon
                          size="15"
                          icon="InfoIcon"
                        />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                      id="ubigee"
                      v-model="ubigee"
                      :formatter="formatterNumber"
                      :state="errors.length > 0 ? false:null"
                      name="ubigee"
                      :disabled="!canEdit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row
                class="justify-content-sm-end my-2"
              >
                <b-col
                  sm="6"
                  lg="8"
                  class="d-flex justify-content-sm-end justify-content-center"
                >
                  <b-badge
                    href="/ubigeo"
                    variant="light-primary"
                    target="_blank"
                    style="white-space: pre-wrap;"
                  > Puede encontrar su UBIGEO haciendo click AQUÍ </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              lg="6"
            >
              <!-- place type -->
              <b-form-group
                label-cols-sm="6"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Tipo de vivienda"
                label-for="placeType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tipo de vivienda"
                  vid="placeType"
                >
                  <b-form-radio-group
                    id="placeType"
                    v-model="placeType"
                    :options="options"
                    value-field="value"
                    text-field="text"
                    :state="errors.length > 0 ? false:null"
                    name="placeType"
                    class="mt-1"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row
          class="justify-content-center"
        >
          <b-col
            sm="6"
            md="3"
          >
            <!-- return button -->
            <b-button
              class="my-1"
              variant="secondary"
              :to="{name:'Personal-data'}"
              block
            >
              Volver
            </b-button>
          </b-col>
          <b-col
            sm="6"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              {{ canEdit ? "Guardar y avanzar" : "Siguiente" }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BCard, BFormGroup, BFormInput, BButton, BRow, BCol, BFormRadioGroup, VBTooltip, BInputGroupPrepend, BInputGroup, BBadge, BFormSelect,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import countriesOptions from '@utils/countriesOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormRadioGroup,
    BInputGroupPrepend,
    BInputGroup,
    BBadge,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      email: JSON.parse(localStorage.getItem('userData')).email,
      ruc: JSON.parse(localStorage.getItem('userData')).ruc,
      phone: '',
      homephone: '',
      address: '',
      street: '',
      streetNumber: '',
      block: '',
      lot: '',
      apartmentNumber: '',
      inside: '',
      district: '',
      province: '',
      department: '',
      country: '',
      reference: '',
      ubigee: '',
      // Options values
      options: [
        { value: '0', text: 'Propia' },
        { value: '1', text: 'Familiar' },
        { value: '2', text: 'Alquilada' },
      ],
      optionsCountry: countriesOptions,
      placeType: JSON.parse(localStorage.getItem('userData')).contactLocationData && JSON.parse(localStorage.getItem('userData')).contactLocationData.placeType ? JSON.parse(localStorage.getItem('userData')).contactLocationData.placeType : null,
      // Validation rules
      required,
      userType: null,
      canEdit: JSON.parse(localStorage.getItem('userData')).canEdit || false,
    }
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.userType = dataU.type
    if (dataU.type === 0) {
      this.canEdit = true
    }
    this.phone = dataU.contactLocationData && dataU.contactLocationData.phone ? dataU.contactLocationData.phone : ''
    this.homephone = dataU.contactLocationData && dataU.contactLocationData.homephone ? dataU.contactLocationData.homephone : ''
    this.address = dataU.contactLocationData && dataU.contactLocationData.address ? dataU.contactLocationData.address : ''
    this.street = dataU.contactLocationData && dataU.contactLocationData.street ? dataU.contactLocationData.street : ''
    this.streetNumber = dataU.contactLocationData && dataU.contactLocationData.streetNumber ? dataU.contactLocationData.streetNumber : ''
    this.block = dataU.contactLocationData && dataU.contactLocationData.block ? dataU.contactLocationData.block : ''
    this.lot = dataU.contactLocationData && dataU.contactLocationData.lot ? dataU.contactLocationData.lot : ''
    this.apartmentNumber = dataU.contactLocationData && dataU.contactLocationData.apartmentNumber ? dataU.contactLocationData.apartmentNumber : ''
    this.inside = dataU.contactLocationData && dataU.contactLocationData.inside ? dataU.contactLocationData.inside : ''
    this.district = dataU.contactLocationData && dataU.contactLocationData.district ? dataU.contactLocationData.district : ''
    this.province = dataU.contactLocationData && dataU.contactLocationData.province ? dataU.contactLocationData.province : ''
    this.department = dataU.contactLocationData && dataU.contactLocationData.department ? dataU.contactLocationData.department : ''
    this.country = dataU.contactLocationData && dataU.contactLocationData.country ? dataU.contactLocationData.country : ''
    this.reference = dataU.contactLocationData && dataU.contactLocationData.reference ? dataU.contactLocationData.reference : ''
    this.ubigee = dataU.contactLocationData && dataU.contactLocationData.ubigee ? dataU.contactLocationData.ubigee : ''
  },
  methods: {
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    validationForm() {
      if (this.userType === 1 && !this.canEdit) {
        this.$router.replace('/registro/datos-profesionales')
      } else {
        this.$refs.contactLocationDataForm.validate().then(success => {
          if (success) {
            useJwt.contactLocationData({
              phone: this.phone,
              homephone: this.homephone,
              address: this.address,
              street: this.street,
              streetNumber: this.streetNumber,
              block: this.block,
              lot: this.lot,
              apartmentNumber: this.apartmentNumber,
              inside: this.inside,
              companySize: this.companySize,
              district: this.district,
              province: this.province,
              department: this.department,
              country: this.country,
              reference: this.reference,
              ubigee: this.ubigee,
              placeType: this.placeType,
              ruc: this.ruc,
              email: this.email,
            })
              .then(({ data }) => {
                const user = data.user.value
                localStorage.setItem('userData', JSON.stringify(user))
                console.log('user', user)
                this.email = user.email
                store.dispatch(
                  'elementVerticalMenu/setStatusStep',
                  { step: 'Contact-data', status: user.contactLocationData.completed },
                  { root: true },
                )
                this.$router.replace('/registro/datos-profesionales')
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Éxito',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Se ha guardado tu información con éxito.',
                    autoHideDelay: 5000,
                  },
                })
              })
              .catch(error => {
                console.log('errores', error)
                this.$refs.contactLocationDataForm.setErrors(error.response.data.error)
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
